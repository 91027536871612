import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container } from "react-bootstrap"

// static assets like pdf for link downloads //
import home_design_trends from "../images/awards/articles/home-&-design-trends-vol-6-no-8-2019.pdf"
import architecture_update from "../images/awards/articles/architecture-update_pg-46-&-47_november-2017.pdf"
import livingetc_cityetc from "../images/awards/articles/livingetc-cityetc-october-2018.pdf"

const AwardsnMediaList = () => {
  return (
    <section className="awards_area pad_btm">
      <Container className="container">
        <Row>
          <div className="awards_logo_list">
            <div className="award_thumb_img">
              <a
                href="https://www.architecturaldigest.in/content/these-large-shipping-containers-in-bengaluru-have-been-refurbished-into-a-chic-new-restaurant/"
                target="_blank"
                rel="noreferrer noopener"
                title="Architectural Digest India - 15 March 2019"
              >
                <StaticImage
                  src="../images/awards/thumbs/Architectural Digest-3-march-2019.jpg"
                  alt="Architectural Digest India"
                />
                <p>
                  Bengaluru gets an edgy restaurant from refurbished shipping
                  containers
                  <br />
                  <span>15 March 2019</span>
                </p>
              </a>
            </div>
            <div className="award_thumb_img">
              <a
                href="https://www.architecturaldigest.in/content/cantan-bangalore-chinese-bar-restaurant-wda-design-firm/"
                target="_blank"
                rel="noreferrer noopener"
                title="Architectural Digest India - 26 June 2019"
              >
                <StaticImage
                  src="../images/awards/thumbs/Architectural Digest-1-may-june-2019.png"
                  alt="Architectural Digest India"
                />
                <p>
                  This Oriental restaurant packs in delectable dishes and a
                  distinctive decor
                  <br />
                  <span>26 June 2019</span>
                </p>
              </a>
            </div>
            <div className="award_thumb_img">
              <a
                href="https://www.architecturaldigest.in/content/xoox-mill-transformed-brewery-bengaluru/"
                target="_blank"
                rel="noreferrer noopener"
                title="Architectural Digest India - 15 September 2018"
              >
                <StaticImage
                  src="../images/awards/thumbs/AD_Sept2018-232x300.jpg"
                  alt="Architectural Digest India"
                />
                <p>
                  This brand new brewmill was once an actual mill <br />
                  <span>15 September 2018</span>
                </p>
              </a>
            </div>

            <div className="award_thumb_img">
              <Link
                to="/awards-&amp;-media/deccan-chronicle-may-10-2019"
                title="Deccan Chronicle - May 10th 2019"
              >
                <StaticImage
                  src="../images/awards/thumbs/deccan-chronicle_10.png"
                  alt="Deccan Chronicle - May 10th 2019"
                />
                <p>
                  Deccan Chronicle
                  <br />
                  <span>May 10th 2019</span>
                </p>
              </Link>
            </div>
            <div className="award_thumb_img">
              <a
                href="https://www.gqindia.com/live-well/content/the-olive-groups-cantan-chinese-restaurant-and-bar-in-bangalore-offers-chinese-with-a-contemporary-twist-cantan-restaurant-menu-location-what-to-eat"
                target="_blank"
                rel="noreferrer noopener"
                title="GQ Online - 20 May, 2019"
              >
                <StaticImage
                  src="../images/awards/thumbs/GQ_cover-223x300.jpg"
                  alt="GQ Online - 20 May, 2019"
                />
                <p>
                  GQ Online <br />
                  <span>20 May 2019</span>
                </p>
              </a>
            </div>
            <div className="award_thumb_img">
              <a href={home_design_trends} title="Download PDF" download>
                <StaticImage
                  src="../images/awards/thumbs/jatinHukkeri1-300x198.jpg"
                  alt="Home &amp; Design Trends Vol 6 No 8 2019"
                />
                <p>
                  Home &amp; Design Trends <br />
                  <span>Vol 6 No 8 2019</span>
                </p>
              </a>
            </div>
            <div className="award_thumb_img">
              <a
                href="https://www.designboom.com/architecture/wda-noodle-shop-mumbai-asian-interiors-06-29-2018/?utm_source=designboom+daily&utm_medium=email&utm_campaign=WDA+designs+izakaya"
                target="_blank"
                rel="noreferrer noopener"
                title="designboom - June 29, 2018"
              >
                <StaticImage
                  src="../images/awards/thumbs/Designboom-June-2018-223x300.jpg"
                  alt="barbecue by punjab grill"
                />
                <p>
                  designboom <br />
                  <span>June 29, 2018</span>
                </p>
              </a>
            </div>

            <div className="award_thumb_img">
              <a href={livingetc_cityetc} title="Download PDF" download>
                <StaticImage
                  src="../images/awards/thumbs/Livingetc_October-2018.jpg"
                  alt="Livingetc - October 2018"
                />
                <p>
                  Livingetc <br />
                  <span>October 2018</span>
                </p>
              </a>
            </div>
            <div className="award_thumb_img">
              <a href={architecture_update} title="Download PDF" download>
                <StaticImage
                  src="../images/awards/thumbs/AI_Cover-223x300.jpg"
                  alt="Architecture Update November 2017"
                />
                <p>
                  Architecture Update <br />
                  <span>November 2017</span>
                </p>
              </a>
            </div>
            <div className="award_thumb_img">
              <Link
                to="/awards-&amp;-media/sunday-times-of-india-june-15-2014"
                title="Sunday Times Of India - June 15 2014"
              >
                <StaticImage
                  src="../images/awards/thumbs/TOI_cover-223x300.jpg"
                  alt="Sunday Times Of India - June 15 2014"
                />
                <p>
                  Sunday Times Of India
                  <br />
                  <span>June 15 2014</span>
                </p>
              </Link>
            </div>
            <div className="award_thumb_img">
              <Link
                to="/awards-&amp;-media/deccan-herald-july-11-2014"
                title="Deccan Herald - July 11 2014"
              >
                <StaticImage
                  src="../images/awards/thumbs/DH_cover-223x300.jpg"
                  alt="Deccan Herald - July 11 2014"
                />
                <p>
                  Deccan Herald <br />
                  <span>July 11 2014</span>
                </p>
              </Link>
            </div>
            <div className="award_thumb_img">
              <Link
                to="/awards-&amp;-media/bangalore-mirror-july-2014"
                title="Bangalore Mirror - July 2014"
              >
                <StaticImage
                  src="../images/awards/thumbs/BM_cover-223x300.jpg"
                  alt="Bangalore Mirror - July 2014"
                />

                <p>
                  Bangalore Mirror
                  <br />
                  <span>July 2014</span>
                </p>
              </Link>
            </div>
            <div className="award_thumb_img">
              <Link
                to="/awards-&amp;-media/society-interiors-august-2014"
                title="Society Interiors - August 2014"
              >
                <StaticImage
                  src="../images/awards/thumbs/im-Coverpage-212x300.jpg"
                  alt="Society Interiors - August 2014"
                />
                <p>
                  Society Interiors <br />
                  <span>August 2014</span>
                </p>
              </Link>
            </div>
            <div className="award_thumb_img">
              <Link
                to="/awards-&amp;-media/bob-magazine-169th-edition"
                title="Bob Magazine - 169th edition"
              >
                <StaticImage
                  src="../images/awards/thumbs/COVER-BOB-MAGAZINE-237x300.jpg"
                  alt="Bob Magazine - 169th edition"
                />
                <p>
                  Bob Magazine
                  <br />
                  <span>169th edition </span>
                </p>
              </Link>
            </div>
          </div>
        </Row>
      </Container>
    </section>
  )
}
export default AwardsnMediaList
