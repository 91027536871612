import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./awards-hero.yaml"

const AwardsnMediaHero = () => {
  return (
    <section className="awardshero_area pad_btm">
      <Container className="p0" fluid>
        <Row className="no-gutters">
          <Col
            xs={{ order: 2, span: 12 }}
            lg={{ order: 1, span: 5 }}
            xxl={{ order: 1, span: 4 }}
            className="awardshero_content"
          >
            <div className="awardshero_content_ph">
              <h2>{YAMLdata.title}</h2>
              <p className="black-dash">{YAMLdata.content}</p>
            </div>
          </Col>
          <Col
            xs={{ order: 1, span: 12 }}
            lg={{ order: 2, span: 7 }}
            xxl={{ order: 2, span: 8 }}
            className="awardshero_image"
          >
            <StaticImage
              src="../images/awards/awards-hero.jpg"
              alt="Awards &amp; Media"
              layout="fullWidth"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default AwardsnMediaHero
