import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import AwardsnMediaHero from "../../sections/awardsnmedia-hero"
import AwardsnMediaList from "../../sections/awardsnmedia-list"

const AwardsAndMedia = () => (
  <Layout>
    <Seo title="Awards and Media" />
    <AwardsnMediaHero />
    <AwardsnMediaList />
  </Layout>
)

export default AwardsAndMedia
